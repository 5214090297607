import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "scrollContainer",
  class: "cars-list-container"
}
const _hoisted_2 = ["data-state"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Toast"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cars, (car, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: car.id
      }, [
        (index === $setup.cars.findIndex(c => c.state === car.state))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "cars-list-header",
              "data-state": $setup.STATES_LIST.find(s => s.value === car.state).name
            }, null, 8 /* PROPS */, _hoisted_2))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["CarItem"], {
          car: car,
          index: index
        }, null, 8 /* PROPS */, ["car", "index"])
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 512 /* NEED_PATCH */))
}