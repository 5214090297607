<script setup>
import { sortBy } from "lodash-es"
import { PlusIcon } from "lucide-vue-next"
import { computed, onMounted, ref } from "vue"

import CalendarCar from "../../../../companies/static_src/companies/components/calendar/CalendarCar.vue"
import CalendarChargeChart from "../../../../companies/static_src/companies/components/calendar/CalendarChargeChart.vue"

import { serializeCar, serializeDelayedWork } from "../../../../companies/static_src/companies/serializers/car.js"
import { getCarsOfWeek, getDelayedWorksOfWeek } from "../../../../companies/static_src/companies/services/api.js"
import { useCalendarStore } from "../../../../companies/static_src/companies/stores/calendar.js"
import { computeWork, getStateDate, isOnlyMechanic } from "../../../../companies/static_src/companies/utils/cars.js"

const capacity = {
  sheetMetalWork: 14,
  paintWork: 14,
  mechanicalWork: 7,
}

const STATE_ORDER = {
  coming: 1,
  expertise: 2,
  todo: 3,
  repairing: 4,
  reassembly: 5,
  finished: 6,
  delivered: 7,
  waiting: 8,
}

const carOrder = (object) => {
  const carObject = object.type === "work" ? object.work.car : object.car
  const stateCount = Object.keys(STATE_ORDER).length

  if (carObject.is_deleted) {
    return 2 * stateCount + 3 + STATE_ORDER[carObject.state]
  }

  if (carObject.state === "breakage") {
    return 2 * stateCount + 2
  }

  if (isOnlyMechanic(carObject)) {
    return stateCount + STATE_ORDER[carObject.state]
  }

  return STATE_ORDER[carObject.state]
}

const cars = ref([])
const delayedWorks = ref([])
const calendarStore = useCalendarStore()

const dayWork = computed(() => computeWork(cars.value, delayedWorks.value))
const slots = computed(() => {
  const slots = []
  cars.value.forEach((car) => {
    slots.push({
      type: "car",
      display: car.delayed_works.length === 0,
      car,
    })
  })
  delayedWorks.value.forEach((work) => {
    slots.push({
      type: "work",
      display: work.is_last,
      work,
    })
  })
  return sortBy(slots, carOrder)
})

onMounted(() => {
  calendarStore.isHomepage = true
  getCarsOfWeek("2024-09-23", 2).then(({ data }) => {
    cars.value = data.map(c => serializeCar(c)).filter(c => new Date(getStateDate(c)).getDate() === 26)
  })

  getDelayedWorksOfWeek("2024-09-23", 2).then(({ data }) => {
    delayedWorks.value = data.map(w => serializeDelayedWork(w)).filter(w => new Date(w.date).getDate() === 26)
  })
})
</script>

<template>
  <div class="calendar-week-day">
    <div class="calendar-week-day-name">
      Jeudi 26/09
    </div>
    <div class="calendar-week-day-work">
      <CalendarChargeChart
        :work="dayWork"
        :capacity="capacity"
        font-size-value="small"
      />
    </div>
    <div class="calendar-week-day-cars">
      <CalendarCar
        v-for="slot in slots.filter(slot => slot.display)"
        :key="slot.type === 'car' ? slot.car.id : slot.work.car.id"
        :object="slot"
      />
      <button class="add-car-button">
        <PlusIcon
          size="20"
          class="add-car-button-icon"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../base/mixins";

.calendar-week-day {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;
  border-radius: var(--radius);
  padding: 0.4rem 0.2rem;
  gap: .2rem;
  transition: transform var(--p-transition-duration), box-shadow var(--p-transition-duration), background-color var(--p-transition-duration), z-index var(--p-transition-duration);
  height: fit-content;
  z-index: 1;
}

.calendar-week-day-name {
  @include body;
  text-align: center;
  width: 100%;
}

.calendar-week-day.-focusable:hover .calendar-week-day-name {
  z-index: inherit;
}

.calendar-week-day.-focusable .calendar-week-day-work,
.calendar-week-day.-focusable .calendar-week-day-cars {
  pointer-events: none;
}

.calendar-week-day-work {
  display: block;
  width: 100%;
  height: 5rem;
}

.calendar-week-day-cars {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}

.add-car-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  height: 1.6rem;
}

.add-car-button-icon {
  color: var(--color-icon);
}

.add-car-button:hover .add-car-button-icon {
  color: var(--color-icon-focus);
}
</style>
