<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { CircleCheck, StickyNote, UserRound } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { computed, h } from "vue"
import { renderToString } from "vue/server-renderer"

import CarState from "../_partials/CarState.vue"
import CarFollowUp from "../tooltips/CarFollowUp.vue"
import ReplacementCar from "../tooltips/ReplacementCar.vue"

import { isFollowupComplete } from "../../../../../companies/static_src/companies/utils/cars.js"
import { getFullDate } from "../../../../../ui/static_src/ui/utils/date.js"
import { svgLink } from "../../../../../ui/static_src/ui/utils/svg.js"
import { useCarsStore } from "../../stores/cars.js"

const carsStore = useCarsStore()
const { cars } = storeToRefs(carsStore)

const props = defineProps({
  car: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const isReplacementFinished = computed(() => {
  if (props.car.replacements.length === 0) {
    return false
  }
  return props.car.replacements[0].finished && new Date(props.car.replacements[0].end_date).getTime() < new Date().getTime()
})

const replacementTooltipOptions = asyncComputed(async() => {
  if (props.car.replacements.length === 0) {
    return { escape: false, value: "Aucun véhicule de remplacement" }
  }
  const htmlContent = await renderToString(h(ReplacementCar, {
    replacement: props.car.replacements[0],
  }))
  return { escape: false, value: htmlContent }
})

const isFollowupCompleteComputed = computed(() => {
  return isFollowupComplete(props.car)
})

const carFollowupTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarFollowUp, { car: props.car }))
  return { escape: false, value: htmlContent }
})

const totalWorks = computed(() => {
  return props.car.sheet_metal_work_hours + props.car.painting_hours + props.car.mechanic_hours
})

const openCarInChargeModal = (event) => {
  event.preventDefault()
  event.stopPropagation()
  carsStore.openCarInChargeForm(props.car)
}
</script>

<template>
  <a
    :class="[
      'cars-list-item',
      {
        '-first': index === cars.findIndex(c => c.state === car.state)
      }
    ]"
    :href="`${carsStore.baseUrl}vehicule/en-charge/${car.id}/`"
  >
    <div class="cars-list-item-left">
      <CarState
        :object="{ car, type: 'car' }"
        class="car-state-container-parent"
        :is-homepage="carsStore.isHomepage"
        :update-car-state="carsStore.updateCarState"
        :update-car-in-charge="carsStore.addMofifications"
      />
      <div class="cars-list-item-slot">
        <svg
          v-if="car.need_replacement"
          v-tooltip.left="replacementTooltipOptions"
          :class="['replacement-car-icon', {'-finished': isReplacementFinished}]"
        >
          <use :href="svgLink('cars', 'car-replacement')" />
        </svg>
      </div>
      <div class="car-name">
        <UserRound class="car-icon" />
        {{ car.owner_last_name }}
      </div>
      <div class="car-name">
        <svg class="car-icon">
          <use :href="svgLink('cars', 'car')" />
        </svg>
        {{ car.brand }} {{ car.model }}
      </div>
      <div
        v-if="car.foreground_note"
        class="car-name"
      >
        <StickyNote class="car-icon" />
        {{ car.foreground_note }}
      </div>
    </div>
    <div class="cars-list-item-right">
      <div
        v-if="['todo', 'coming'].includes(car.state)"
        class="car-date"
      >
        <span>Arrive le</span> {{ getFullDate(car.start_work) }}
      </div>
      <CircleCheck
        v-if="car.wreck_delivered"
        v-tooltip.top="`Épave livrée`"
        size="20"
        class="weck-delivered-icon"
      />
      <span
        v-if="car.from_tow_truck && ['waiting', 'breakage'].includes(car.state)"
        v-tooltip.top="`Arrivé par dépannage`"
      >
        <svg class="tow-truck-icon">
          <use :href="svgLink('cars', 'tow-truck')" />
        </svg>
      </span>
      <span
        v-if="!car.from_tow_truck && ['waiting', 'breakage'].includes(car.state)"
        style="width: 1.2rem;"
      />
      <div
        v-if="car.state === 'waiting'"
        class="car-date"
      >
        <span>En attente depuis le</span> {{ car.waiting_date ? getFullDate(car.waiting_date) : getFullDate(car.start_work) }}
      </div>
      <div
        v-if="car.state === 'breakage'"
        class="car-date"
      >
        <span>Passage à la casse le</span> {{ getFullDate(car.breakage_date) }}
      </div>
      <div
        v-if="car.state === 'expertise'"
        class="car-date"
      >
        <span>Expertise le</span> {{ getFullDate(car.expertise_date) }}
      </div>
      <div
        v-if="car.state === 'finished' && !car.invoice_ready"
        class="car-invoice"
      >
        <span>Facture à préparer</span>
      </div>
      <div
        v-if="car.state === 'finished' && car.invoice_ready"
        class="car-invoice"
      >
        <CircleCheck
          size="20"
          class="invoice-ready-icon"
        />
        <span>Facture prête</span>
      </div>
      <div
        v-if="['todo', 'coming', 'repairing', 'reassembly'].includes(car.state) && totalWorks > 0"
        class="car-works"
      >
        <div
          v-if="car.sheet_metal_work_hours > 0"
          class="car-work -sheet-metal"
          :style="{ width: `${car.sheet_metal_work_hours / totalWorks * 100}%` }"
        >{{ Math.floor(car.sheet_metal_work_hours * 10) / 10 }} T</div>
        <div
          v-if="car.painting_hours > 0"
          class="car-work -paint"
          :style="{ width: `${car.painting_hours / totalWorks * 100}%` }"
        >{{ Math.floor(car.painting_hours * 10) / 10 }} P</div>
        <div
          v-if="car.mechanic_hours > 0"
          class="car-work -mechanic"
          :style="{ width: `${car.mechanic_hours / totalWorks * 100}%` }"
        >{{ Math.floor(car.mechanic_hours * 10) / 10 }} M</div>
      </div>
      <div
        v-if="['todo', 'coming', 'repairing', 'reassembly'].includes(car.state) && totalWorks === 0"
        class="car-works"
      >
        <span>Charge horaire non renseignée</span>
      </div>
      <div
        v-tooltip.left="carFollowupTooltipOptions"
        class="car-followup"
      >
        <div
          v-if="isFollowupCompleteComputed"
          class="car-followup-point -complete"
        />
        <div
          v-else
          class="car-followup-point -incomplete"
        />
      </div>
      <button
        class="cars-list-item-update"
        @click="openCarInChargeModal"
      >
        Modifier
      </button>
    </div>
  </a>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.cars-list-item {
  display: flex;
  padding: .4rem .8rem;
  border: 0.4px solid var(--color-border-primary);
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-right: none;
  border-left: none;
  border-radius: 0;

  &:not(.-first) {
    border-top: none;
  }

  &:has(.cars-list-item-update:hover),
  &:has(.car-state-container-parent:hover) {
    background-color: initial;
  }
}

.cars-list-item-left,
.cars-list-item-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cars-list-item-right {
  justify-content: flex-end;
}

.car-name,
.car-date,
.car-invoice {
  @include body;
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: .4rem;

  &> span {
    color: var(--color-text-secondary);
  }
}

.car-icon {
  width: 1.2rem;
  height: 1.2rem;
  min-width: 1.2rem;
  color: var(--color-icon);
}

.car-date,
.car-invoice {
  width: fit-content;
}

.cars-list-item-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
}

.replacement-car-icon {
  width: 1.4rem;
  height: 1.4rem;
  color: var(--color-text-submit);

  &.-finished {
    color: var(--color-text-success);
  }
}

.cars-list-item-update {
  @include body;
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: .2rem;
  width: 5.5rem;
  justify-content: center;
  opacity: .9;

  &:hover {
    opacity: 1;
  }
}

.car-works {
  @include body;
  display: flex;
  width: 12.5rem;
  border-radius: var(--radius);
  overflow: hidden;

  &> span {
    color: var(--color-text-secondary);
  }
}

.car-work {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-quaternary);
  min-width: 3rem;

  &.-sheet-metal {
    background-color: var(--color-bg-bar-sheet-metal-work);
  }

  &.-paint {
    background-color: var(--color-bg-bar-painting);
  }

  &.-mechanic {
    background-color: var(--color-bg-bar-mechanic);
  }
}

.car-followup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 .4rem;
  min-width: .4rem;
  height: 100%;
}

.car-followup-point {
  width: .4rem;
  height: .4rem;
  border-radius: 50%;

  &.-incomplete {
    background-color: var(--car-followup-incomplete);
  }

  &.-complete {
    background-color: var(--car-followup-complete);
  }
}

.tow-truck-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-icon);
}

.weck-delivered-icon,
.invoice-ready-icon {
  color: var(--color-text-success);
}
</style>
