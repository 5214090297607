// --- Server API ---

export const getCars = async(queryParams, companyPk, page, body) => {
  const { query, states } = queryParams
  const statesString = states.join("&state=")
  return await api("GET", `/api/cars-in-charge/?query=${query}&company_pk=${companyPk}&state=${statesString}&page=${page}&search=true`, body)
}

export const getCarInCharge = async(carPk) => {
  return await api("GET", `/api/cars-in-charge/${carPk}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const getReplacementCar = async(carPk) => {
  return await api("GET", `/api/cars-replacements/${carPk}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const putReplacementCar = async(body) => {
  return await api("PUT", `/api/cars-replacements/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const createMaintenanceTime = async(body) => {
  return await api("POST", `/api/maintenance-times/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const putMaintenanceTime = async(body) => {
  return await api("PUT", `/api/maintenance-times/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const deleteMaintenanceTime = async(maintenanceTimeId) => {
  return await api("DELETE", `/api/maintenance-times/${maintenanceTimeId}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const updateCarInCharge = async(body) => {
  return await api("PATCH", `/api/cars-in-charge/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const getInsurances = async(name, excludeList) => {
  let url = `/api/insurances/?query=${name}`
  if (excludeList) {
    excludeList.forEach(pk => {
      url += `&exclude=${pk}`
    })
  }
  return await api("GET", url)
}

export const createInsurance = async(name) => {
  return await api("POST", "/api/insurances/", { name })
}

export const updateCarState = async(body) => {
  return await api("POST", `/api/cars-in-charge/${body.id}/change-state/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

// --- Internals ---

const api = async(method, path, body) => {
  body = body || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await fetch(path, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": window.CSRF_TOKEN,
    },
    body: method === "GET" ? undefined : JSON.stringify(body),
  })
  if (response.ok && !path.includes("format=")) {
    try {
      data = await response.json()
    } catch (e) {
      // No-op
    }
  }
  return { response, data }
}
