<script setup>
import { storeToRefs } from "pinia"
import Toast from "primevue/toast"
import { onMounted } from "vue"

import CarItem from "../../../../cars/static_src/cars/components/list/CarItem.vue"

import { useCarsStore } from "../../../../cars/static_src/cars/stores/cars.js"
import { STATES_LIST } from "../../../../cars/static_src/cars/utils/states.js"

const carsStore = useCarsStore()
const { cars } = storeToRefs(carsStore)

onMounted(async() => {
  await carsStore.init(window.CARS_ENV, { query: "", selectedStates: ["coming"] })
  carsStore.fetchCars(1)
})
</script>

<template>
  <div
    ref="scrollContainer"
    class="cars-list-container"
  >
    <Toast />
    <template
      v-for="(car, index) in cars"
      :key="car.id"
    >
      <div
        v-if="index === cars.findIndex(c => c.state === car.state)"
        class="cars-list-header"
        :data-state="STATES_LIST.find(s => s.value === car.state).name"
      />
      <CarItem
        :car="car"
        :index="index"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '../base/mixins';

.cars-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: .3rem;
}

.cars-list-header {
  @include title;
  display: block;
  padding: .4rem .8rem;
  text-align: left;

  &::before {
    content: attr(data-state);
  }
}
</style>
